import { Flex } from "antd";
import DatePickerMonthsRange from "./ui/datePickerMonthsRange";
import OrderFilterCheckBox from "./ui/orderFilterCheckBox";
import { useEffect, useState } from "react";
import { getSaleCountForGroupSaleByMonth } from "../../axios/GroupSale";
import SalesDataTable from "./ui/salesDataTable";
import { getOtooDeliveryChannelAndShipmentDateBetweenByDate } from "../../axios/Otoo";
import DailySalesDataTable from "./ui/dailySalesDataTable";
import GroupSalesDataTable from "./ui/groupSalesDataTable";
import DailyGroupSalesDataTable from "./ui/dailyGroupSalesDataTable";
import dayjs from "dayjs";

const SalesByProduct = () => {
    const [searchPeriod, setSearchPeriod] = useState<Array<string>>(['2024-04', dayjs().format('YYYY-MM')]);
    const [orderFilter, setOrderFilter] = useState<string>('전체');
    const [groupSaleDataList, setGroupSaleDataList] = useState<Array<IGroupSaleData> | undefined>(undefined);
    const [otooItemTableDataList, setOtooItemTableDataList] = useState<IChannelAndShipmentDateBetween[] | undefined>(undefined);
    const [selectedDailyData, setSelectedDailyData] = useState<IOtooDeliveryShipmentDateBetweenAndProductNameByDay | undefined>(undefined);
    const [selectedDailyGroupSaleData, setSelectedDailyGroupSaleData] = useState<ISelectedDailyGroupSaleData | undefined>(undefined);

    useEffect(() => {
        getOtooDeliveryChannelAndShipmentDateBetweenByDate(searchPeriod[0], searchPeriod[1])
            .then(res => setOtooItemTableDataList(res.data.sort((q, w) => w.total - q.total)))
            .catch(err => console.error(err))

        getSaleCountForGroupSaleByMonth(searchPeriod[0], searchPeriod[1])
            .then(res => setGroupSaleDataList(res.data))
            .catch(err => console.error(err));
    }, [searchPeriod])

    const getSalesDataWithoutGroupSales = () => {
        if (!otooItemTableDataList) return undefined;
        if (!groupSaleDataList) return undefined;

        const adjustedSaleData = otooItemTableDataList.map(saleItem => {
            const updatedSaleItem = { ...saleItem };
            const eventItem = groupSaleDataList.flatMap(e => e.data).find((e: IGroupSaleRowData) => e.itemcode === saleItem.itemCode);
            if (eventItem) {
                Object.keys(updatedSaleItem).forEach(key => {
                    if (key !== "itemCode" && key !== "itemName" && !isNaN(updatedSaleItem[key])) {
                        updatedSaleItem[key] = (updatedSaleItem[key] || 0) - (eventItem[key] || 0);
                    }
                });
            }
            return updatedSaleItem;
        });
        return adjustedSaleData;
    };

    return <>
        <Flex style={{ alignItems: 'center' }}>
            <div style={{ width: '100px' }}>기간</div>
            <DatePickerMonthsRange value={searchPeriod} onChange={setSearchPeriod} />
        </Flex>
        <br />
        <Flex style={{ alignItems: 'center' }}>
            <div style={{ width: '100px' }}>주문 필터</div>
            {groupSaleDataList && <OrderFilterCheckBox
                groupSaleNameList={groupSaleDataList.map((e: IGroupSaleData) => e.name)}
                onChange={(e) => setOrderFilter(e.target.value)} value={orderFilter}
            />}
        </Flex>

        <br />
        {(orderFilter === '전체' || orderFilter === '공구 상품 제외') ? <>
            {otooItemTableDataList && <>
                <SalesDataTable
                    dataList={orderFilter === '전체' ? otooItemTableDataList : getSalesDataWithoutGroupSales()}
                    onClickCell={(value: IOtooDeliveryShipmentDateBetweenAndProductNameByDay) => setSelectedDailyData(value)}
                />
            </>}

            {selectedDailyData && <>
                <DailySalesDataTable targetValue={selectedDailyData} isExceptGorupSale={orderFilter === '전체'} />
            </>}
        </> : <>
            {groupSaleDataList && <>
                <GroupSalesDataTable
                    dataList={groupSaleDataList.find((e: IGroupSaleData) => e.name === orderFilter)?.data}
                    onClickCell={(value: ISelectedDailyGroupSaleData) => setSelectedDailyGroupSaleData({ ...value, groupSaleName: orderFilter })} />
            </>}

            {selectedDailyGroupSaleData && <>
                <DailyGroupSalesDataTable targetValue={selectedDailyGroupSaleData} />
            </>
            }

        </>}
        <br />
        <br />
    </>
}

export default SalesByProduct;