import { createReducer } from 'typesafe-actions';
import { GoodsState, GoodsAction } from './types';
import {
    GET_GOODSLIST,
    GET_GOODSLIST_SUCCESS,
    GET_GOODSLIST_ERROR
} from './actions';


const initialState: GoodsState = {
    goodsList: {
        goodsLoading: false,
        goodsError: null,
        goodsData: null
    }
};

const goods = createReducer<GoodsState, GoodsAction>(initialState, {
    [GET_GOODSLIST]: state => ({
        ...state,
        goodsList: {
            goodsLoading: true,
            goodsError: null,
            goodsData: null
        }
    }),
    [GET_GOODSLIST_SUCCESS]: (state, action) => ({
        ...state,
        goodsList: {
            goodsLoading: false,
            goodsError: null,
            goodsData: action.payload
        }
    }),
    [GET_GOODSLIST_ERROR]: (state, action) => ({
        ...state,
        goodsList: {
            goodsLoading: false,
            goodsError: action.payload,
            goodsData: null
        }
    }),
});

export default goods;