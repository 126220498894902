import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_GOODSLIST = 'goods/GET_GOODSLIST';
export const GET_GOODSLIST_SUCCESS = 'goods/GET_GOODSLIST_SUCCESS';
export const GET_GOODSLIST_ERROR = 'goods/GET_GOODSLIST_ERROR';

export const getGoodsListAsync = createAsyncAction(
    GET_GOODSLIST,
    GET_GOODSLIST_SUCCESS,
    GET_GOODSLIST_ERROR
)<undefined, Array<IGoodsData>, AxiosError>();


