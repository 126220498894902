import { ResponsiveLine } from '@nivo/line'

interface IPrors {
    salesData: any
    xLegend?: string;
    yLegend?: string;
}

const DashBoardChart = ({ salesData, xLegend, yLegend }: IPrors) => {

    return <>
        <ResponsiveLine
            data={salesData}
            lineWidth={3}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
            colors={["hsl(0, 92%, 50%)", "hsl(30, 92%, 50%)", "hsl(60, 92%, 50%)", "hsl(90, 92%, 50%)", "hsl(120, 92%, 40%)", "hsl(180, 92%, 50%)", "hsl(210, 92%, 50%)", "hsl(240, 92%, 60%)", "hsl(270, 92%, 60%)", "hsl(300, 92%, 50%)"]}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false
            }}
            enableSlices={'x'}
            yFormat=" >-,.0f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yLegend ?? '날짜',
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={{ legend: xLegend ?? '값', legendPosition: 'middle', legendOffset: -40 }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            enablePointLabel={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    </>
}

export default DashBoardChart;