import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { BuyerAction } from './types';
import { getBuyerPositbot } from '../../axios/Buyer';

import { getBuyerListAsync } from './actions';

export function getBuyerListThunk(memberId: string): ThunkAction<void, RootState, null, BuyerAction> {
    return async dispatch => {
        const { request, success, failure } = getBuyerListAsync;
        dispatch(request());
        try {
            const buyerList = await getBuyerPositbot(memberId);
            dispatch(success(buyerList));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};