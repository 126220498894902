import { createReducer } from 'typesafe-actions';
import { DashboardState, DashboardAction } from './types';
import {
    GET_DASHBOARD,
    GET_DASHBOARD_SUCCESS,
    GET_DASHBOARD_ERROR
} from './actions';


const initialState: DashboardState = {
    dashboard: {
        dashboardLoading: false,
        dashboardError: null,
        dashboardData: null
    }
};

const dashboard = createReducer<DashboardState, DashboardAction>(initialState, {
    [GET_DASHBOARD]: state => ({
        ...state,
        dashboard: {
            dashboardLoading: true,
            dashboardError: null,
            dashboardData: null
        }
    }),
    [GET_DASHBOARD_SUCCESS]: (state, action) => ({
        ...state,
        dashboard: {
            dashboardLoading: false,
            dashboardError: null,
            dashboardData: action.payload
        }
    }),
    [GET_DASHBOARD_ERROR]: (state, action) => ({
        ...state,
        dashboard: {
            dashboardLoading: false,
            dashboardError: action.payload,
            dashboardData: null
        }
    }),
});

export default dashboard;