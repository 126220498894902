import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { ReportAction } from './types';
import { getReportPositbot } from '../../axios/Report';

import { getReportAsync } from './actions';

export function getReportThunk(startDate: string, endDate: string): ThunkAction<void, RootState, null, ReportAction> {
    return async dispatch => {
        const { request, success, failure } = getReportAsync;
        dispatch(request());
        try {
            const report = await getReportPositbot(startDate, endDate);
            dispatch(success(report));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};