import { createReducer } from 'typesafe-actions';
import { ReportState, ReportAction } from './types';
import {
    GET_REPORT,
    GET_REPORT_SUCCESS,
    GET_REPORT_ERROR
} from './actions';


const initialState: ReportState = {
    report: {
        reportLoading: false,
        reportError: null,
        reportData: null
    }
};

const report = createReducer<ReportState, ReportAction>(initialState, {
    [GET_REPORT]: state => ({
        ...state,
        report: {
            reportLoading: true,
            reportError: null,
            reportData: null
        }
    }),
    [GET_REPORT_SUCCESS]: (state, action) => ({
        ...state,
        report: {
            reportLoading: false,
            reportError: null,
            reportData: action.payload
        }
    }),
    [GET_REPORT_ERROR]: (state, action) => ({
        ...state,
        report: {
            reportLoading: false,
            reportError: action.payload,
            reportData: null
        }
    }),
});

export default report;