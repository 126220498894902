import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_DASHBOARD = 'dashboard/GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'dashboard/GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_ERROR = 'dashboard/GET_DASHBOARD_ERROR';

export const getDashboardAsync = createAsyncAction(
    GET_DASHBOARD,
    GET_DASHBOARD_SUCCESS,
    GET_DASHBOARD_ERROR
)<undefined, IDashboardData, AxiosError>();


