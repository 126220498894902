import axios from 'axios';

export const getOrderProductPositbot = async (startDate: string, endDate: string): Promise<Array<IProductData>> => {
    return await axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/order/product/${startDate}/${endDate}`)
        .then((res) => res.data);
}

export const getOrderProductName = async (startDate: string, endDate: string): Promise<Array<IOrderProductNameOption>> => {
    return await axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/order/product/name/${startDate}/${endDate}`)
        .then((res) => res.data);
}