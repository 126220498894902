import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_CUSTOMERLIST = 'customer/GET_CUSTOMERLIST';
export const GET_CUSTOMERLIST_SUCCESS = 'customer/GET_CUSTOMERLIST_SUCCESS';
export const GET_CUSTOMERLIST_ERROR = 'customer/GET_CUSTOMERLIST_ERROR';

export const getCustomerListAsync = createAsyncAction(
    GET_CUSTOMERLIST,
    GET_CUSTOMERLIST_SUCCESS,
    GET_CUSTOMERLIST_ERROR
)<undefined, ICustomerData, AxiosError>();


