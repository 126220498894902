import { createReducer } from 'typesafe-actions';
import { ProductState, ProductAction } from './types';
import {
    GET_PRODUCTLIST,
    GET_PRODUCTLIST_SUCCESS,
    GET_PRODUCTLIST_ERROR
} from './actions';


const initialState: ProductState = {
    productList: {
        productLoading: false,
        productError: null,
        productData: null
    }
};

const product = createReducer<ProductState, ProductAction>(initialState, {
    [GET_PRODUCTLIST]: state => ({
        ...state,
        productList: {
            productLoading: true,
            productError: null,
            productData: null
        }
    }),
    [GET_PRODUCTLIST_SUCCESS]: (state, action) => ({
        ...state,
        productList: {
            productLoading: false,
            productError: null,
            productData: action.payload
        }
    }),
    [GET_PRODUCTLIST_ERROR]: (state, action) => ({
        ...state,
        productList: {
            productLoading: false,
            productError: action.payload,
            productData: null
        }
    }),
});

export default product;