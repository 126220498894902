import axios, { AxiosResponse } from 'axios'

export const getSaleCountForGroupSaleByMonth = (startYearMonth: string, endYearMonth: string): Promise<AxiosResponse<any, any>> => {
    return axios.get(`${process.env.REACT_APP_API_DOMAIN}/sale-count/group-sale/month?startYearMonth=${startYearMonth}&endYearMonth=${endYearMonth}`)
}

export const getSaleCountForGroupSaleDaily = (startDate: string, groupSaleTitle: string, productItemCode: string): Promise<AxiosResponse<any, any>> => {
    return axios.get(`${process.env.REACT_APP_API_DOMAIN}/sale-count/group-sale/day?startDate=${startDate}&groupSaleTitle=${groupSaleTitle}&productItemCode=${productItemCode}`)
}

export const getSaleCountForGroupSaleByOneMonth = (startYearMonth: string): Promise<AxiosResponse<any, any>> => {
    return axios.get(`${process.env.REACT_APP_API_DOMAIN}/sale-count/group-sale/one-month?startYearMonth=${startYearMonth}`)
}

