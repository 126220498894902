import { createReducer } from 'typesafe-actions';
import { OrderState, OrderAction } from './types';
import {
    GET_ORDERLIST,
    GET_ORDERLIST_SUCCESS,
    GET_ORDERLIST_ERROR
} from './actions';


const initialState: OrderState = {
    orderList: {
        orderLoading: false,
        orderError: null,
        orderData: null
    }
};

const order = createReducer<OrderState, OrderAction>(initialState, {
    [GET_ORDERLIST]: state => ({
        ...state,
        orderList: {
            orderLoading: true,
            orderError: null,
            orderData: null
        }
    }),
    [GET_ORDERLIST_SUCCESS]: (state, action) => ({
        ...state,
        orderList: {
            orderLoading: false,
            orderError: null,
            orderData: action.payload
        }
    }),
    [GET_ORDERLIST_ERROR]: (state, action) => ({
        ...state,
        orderList: {
            orderLoading: false,
            orderError: action.payload,
            orderData: null
        }
    }),
});

export default order;