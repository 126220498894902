import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";

interface ITableData extends IGroupSaleRowData {
    [key: string]: any;
}

interface IProps {
    dataList: IGroupSaleRowData[] | undefined;
    onClickCell: Function;
}

interface IColumnMapping {
    [key: string]: string | number;
}

const columnMapping: IColumnMapping = {
    itemCode: '코드',
    item_code: '코드',
    itemcode: '코드',
    itemName: '이름',
    itemname: '이름',
    product_name: '이름',
    _29CM: '29CM',
    b2C: 'B2C',
    headQuarter: '본사',
    naverSmartStore: '스마트스토어',
    offlineMarket: '오프라인 매장',
    kakaoGift: '카카오 선물하기',
    kakaoStore: '카카오 스토어',
    kakao: '카카오',
    cafe24: '카페24',
    overseas: '해외',
    total: '합계',
    shipment_date: '날짜',
};

const GroupSalesDataTable = ({ dataList, onClickCell }: IProps) => {
    const [column, setColumn] = useState<ColumnsType<ITableData>>();

    useEffect(() => {
        if (!dataList || dataList.length < 1) return;
        let newColumn: ColumnsType<ITableData> = []
        for (const key in dataList[0]) {
            const title = columnMapping[key] ?? key
            title === '이름'
                ? newColumn.push({
                    title: title,
                    dataIndex: key,
                    width: '330px',
                    render: (q: string) => <div style={{ whiteSpace: 'nowrap', overflow: 'scroll' }}>{q.replace('[파지티브호텔] ', '')}</div>,
                    fixed: 'left'
                })
                : newColumn.push({
                    title: title,
                    dataIndex: key,
                    width: '130px',
                    render: (q, record) => <div style={{ cursor: /^\d{4}-\d{2}$/.test(title.toString()) ? 'pointer' : 'auto' }} onClick={() => {
                        (/^\d{4}-\d{2}$/.test(title.toString())) && onClickCell({ targetYearMonth: `${title}-01`, itemCode: record.itemcode })
                    }}>{q?.toLocaleString() ?? q}</div>
                })
        }
        newColumn.sort((q, w) => {
            const priority: { [key: string]: number } = { '코드': -1, '이름': -1, '합계': 1 };
            const priorityQ = priority[q.title as number] ?? 0;
            const priorityW = priority[w.title as number] ?? 0;
            if (priorityQ !== priorityW) { return priorityQ - priorityW; }
            return (q.title as string).localeCompare(w.title as string);
        });
        setColumn(newColumn)
    }, [dataList])

    return <>
        <div>[공동 구매] 일별 판매 데이터</div>
        <br />
        <Table
            size="small"
            columns={column}
            dataSource={dataList?.map((q, idx) => ({ ...q, key: idx }))} scroll={{ y: 500 }} pagination={false} />
    </>
}

export default GroupSalesDataTable;