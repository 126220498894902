import axios from 'axios';

export const createCustomer = (customerList: Array<ICustomer>) => {
    return axios.post(`${process.env.REACT_APP_BOT_API_DOMAIN}/customer`, customerList)
}

export const getCustomerPositbot = (): Promise<ICustomerData> => {
    return axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/customer/dashboard`)
        .then((res) => res.data)
        .catch((error) => console.error('[axios customer.ts] getCustomerPositbot', error))
}

