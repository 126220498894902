import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

interface IProps {
    value: Array<string>
    onChange: Function
}

const DatePickerMonthsRange = ({ value, onChange }: IProps) => {
    return <>
        <RangePicker
            picker="month"
            value={[dayjs(value[0]), dayjs(value[1])]}
            onChange={(e: any) => onChange([dayjs(e[0]).format('YYYY-MM'), dayjs(e[1]).format('YYYY-MM')])} />
    </>
}

export default DatePickerMonthsRange;