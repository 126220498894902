import ForceGraph from "./ui/ForceGraph"

const Test = () => {

    const nodes = [
        { id: '붓기', group: 1, color: '#FCE4EC', label: '붓기', size: 70 }, // Light pink
        { id: '효과', group: 2, color: '#B3E5FC', label: '효과', size: 65 }, // Light blue
        { id: '맛', group: 3, color: '#C5E1A5', label: '맛', size: 55 }, // Light green
        { id: '편리함', group: 4, color: '#E1BEE7', label: '편리함', size: 50 }, // Light purple
        { id: '배송', group: 5, color: '#FFE0B2', label: '배송', size: 25 }, // Light orange
        { id: '가격', group: 6, color: '#FFCDD2', label: '가격', size: 20 }, // Light red
        { id: '구매 경험', group: 7, color: '#F8BBD0', label: '구매 경험', size: 20 }, // Light pink
        { id: '사은품', group: 8, color: '#D0D0D0', label: '사은품', size: 10 }, // Light grey
        { id: '건강', group: 9, color: '#B2EBF2', label: '건강', size: 25 }, // Light cyan
        { id: '효소', group: 10, color: '#E1BEE7', label: '효소', size: 8 }, // Light magenta
        { id: '재구매', group: 11, color: '#D0D0D0', label: '재구매', size: 22 }, // Light gray
        { id: '디톡스', group: 12, color: '#B2EBF2', label: '디톡스', size: 10 }, // Light cyan
        { id: '소화', group: 13, color: '#E1BEE7', label: '소화', size: 15 }, // Light magenta
        { id: '각질 제거', group: 14, color: '#E1BEE7', label: '각질 제거', size: 10 }, // Light magenta
        { id: '올리브오일', group: 15, color: '#D7CCC8', label: '올리브오일', size: 20 }, // Light brown
        { id: '제품', group: 16, color: '#D7CCC8', label: '제품', size: 40 }, // Light brown
        { id: '포장', group: 17, color: '#F8BBD0', label: '포장', size: 30 }, // Light pink
        { id: '체중조절', group: 18, color: '#B2EBF2', label: '체중조절', size: 5 }, // Light cyan
        { id: '리뷰', group: 19, color: '#D0D0D0', label: '리뷰', size: 5 }, // Light gray
        { id: '포만감', group: 20, color: '#E1BEE7', label: '포만감', size: 15 }, // Light magenta
        { id: '유통기한', group: 21, color: '#B2EBF2', label: '유통기한', size: 8 }, // Light cyan
        { id: '간편함', group: 22, color: '#D0D0D0', label: '간편함', size: 7 }, // Light gray
        { id: '음료수', group: 23, color: '#B2EBF2', label: '음료수', size: 5 }, // Light cyan
        { id: '요거트', group: 24, color: '#80CBC4', label: '요거트', size: 5 }  // Light teal
    ];

    const links = [
        { source: '붓기', target: '효과' },
        { source: '붓기', target: '맛' },
        { source: '붓기', target: '편리함' },
        { source: '붓기', target: '건강' },
        { source: '붓기', target: '재구매' },
        { source: '효과', target: '가격' },
        { source: '효과', target: '사은품' },
        { source: '효과', target: '올리브오일' },
        { source: '효과', target: '디톡스' },
        { source: '효과', target: '소화' },
        { source: '효과', target: '각질 제거' },
        { source: '편리함', target: '재구매' },
        { source: '편리함', target: '포장' },
        { source: '배송', target: '편리함' },
        { source: '배송', target: '사은품' },
        { source: '사은품', target: '효소' },
        { source: '사은품', target: '제품' },
        { source: '제품', target: '가격' },
        { source: '가격', target: '배송' },
        { source: '맛', target: '포만감' },
        { source: '맛', target: '디톡스' },
        { source: '맛', target: '소화' },
        { source: '붓기', target: '재구매' },
        { source: '재구매', target: '배송' },
        { source: '재구매', target: '효소' },
        { source: '디톡스', target: '소화' },
        { source: '요거트', target: '맛' },
        { source: '간편함', target: '재구매' },
        { source: '간편함', target: '효과' }
    ];

    return <div>
        <h1>Force-directed Graph with D3.js and React</h1>
        <ForceGraph nodes={nodes} links={links} />
    </div>
}

export default Test;