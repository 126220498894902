import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_BUYERLIST = 'buyer/GET_BUYERLIST';
export const GET_BUYERLIST_SUCCESS = 'buyer/GET_BUYERLIST_SUCCESS';
export const GET_BUYERLIST_ERROR = 'buyer/GET_BUYERLIST_ERROR';

export const getBuyerListAsync = createAsyncAction(
    GET_BUYERLIST,
    GET_BUYERLIST_SUCCESS,
    GET_BUYERLIST_ERROR
)<undefined, IBuyerData, AxiosError>();


