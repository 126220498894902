import axios from 'axios';

export const getReportPositbot = async (startDate: string, endDate: string): Promise<IReportData> => {
    return axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/report/${startDate}/${endDate}`)
        .then((res) => res.data)
        .catch((error) => console.error('[axios Report.ts] getReportPositbot', error))
}

export const getGoodsReportPositbot = async (startDate: string, endDate: string): Promise<IGoodsReport> => {
    return axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/report/goods/${startDate}/${endDate}`)
        .then((res) => res.data)
        .catch((error) => console.error('[axios Report.ts] getGoodsReportPositbot', error))
}