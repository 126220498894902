import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { OrderAction } from './types';
import { getOrdersPositbot } from '../../axios/Order';

import { getOrderListAsync } from './actions';

export function getOrderListThunk(startDate: string, endDate: string, page: number): ThunkAction<void, RootState, null, OrderAction> {
    return async dispatch => {
        const { request, success, failure } = getOrderListAsync;
        dispatch(request());
        try {
            const orderList = await getOrdersPositbot(startDate, endDate, page);
            dispatch(success(orderList));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};