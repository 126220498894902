import { createReducer } from 'typesafe-actions';
import { SuperAdminState, SuperAdminAction } from './types';
import { UPDATE_SUPERADMIN } from './actions';

const initialState: SuperAdminState = {
    isSuperAdmin: false,
};

const superAdmin = createReducer<SuperAdminState, SuperAdminAction>(initialState, {
    [UPDATE_SUPERADMIN]: (state, action) => ({
        ...state,
        isSuperAdmin: action.payload.isSuperAdmin,
    })
});

export default superAdmin;