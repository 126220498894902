import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { GoodsAction } from './types';
import { getGoodsPositbot } from '../../axios/Goods';

import { getGoodsListAsync } from './actions';

export function getGoodsListThunk(): ThunkAction<void, RootState, null, GoodsAction> {
    return async dispatch => {
        const { request, success, failure } = getGoodsListAsync;
        dispatch(request());
        try {
            const orderGoodsList = await getGoodsPositbot();
            dispatch(success(orderGoodsList));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};