import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { CustomerAction } from './types';
import { getCustomerPositbot } from '../../axios/Customer';

import { getCustomerListAsync } from './actions';

export function getCustomerListThunk(): ThunkAction<void, RootState, null, CustomerAction> {
    return async dispatch => {
        const { request, success, failure } = getCustomerListAsync;
        dispatch(request());
        try {
            const customerList = await getCustomerPositbot();
            dispatch(success(customerList));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};