import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_BUY_REPORT = 'report/GET_BUY_REPORT';
export const GET_BUY_REPORT_SUCCESS = 'report/GET_BUY_REPORT_SUCCESS';
export const GET_BUY_REPORT_ERROR = 'report/GET_BUY_REPORT_ERROR';

export const getBuyReportAsync = createAsyncAction(
    GET_BUY_REPORT,
    GET_BUY_REPORT_SUCCESS,
    GET_BUY_REPORT_ERROR
)<undefined, Array<IBuyReportData>, AxiosError>();


