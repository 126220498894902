import { createReducer } from 'typesafe-actions';
import { BuyerState, BuyerAction } from './types';
import {
    GET_BUYERLIST,
    GET_BUYERLIST_SUCCESS,
    GET_BUYERLIST_ERROR
} from './actions';


const initialState: BuyerState = {
    buyerList: {
        buyerLoading: false,
        buyerError: null,
        buyerData: null
    }
};

const buyer = createReducer<BuyerState, BuyerAction>(initialState, {
    [GET_BUYERLIST]: state => ({
        ...state,
        buyerList: {
            buyerLoading: true,
            buyerError: null,
            buyerData: null
        }
    }),
    [GET_BUYERLIST_SUCCESS]: (state, action) => ({
        ...state,
        buyerList: {
            buyerLoading: false,
            buyerError: null,
            buyerData: action.payload
        }
    }),
    [GET_BUYERLIST_ERROR]: (state, action) => ({
        ...state,
        buyerList: {
            buyerLoading: false,
            buyerError: action.payload,
            buyerData: null
        }
    }),
});

export default buyer;