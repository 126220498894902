import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { DashboardAction } from './types';
import { getDashboardAsync } from './actions';
import axios from 'axios';

export function getDashboardThunk(startDate: string, endDate: string, periodUnit: string, selectedCondition: ISelectedConditionForDashboard, limitCnt: number = 10): ThunkAction<void, RootState, null, DashboardAction> {
    return async dispatch => {
        const { request, success, failure } = getDashboardAsync;
        dispatch(request());
        try {
            axios.all([
                axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/dashboard/sales/${startDate}/${endDate}/${periodUnit}`),
                axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/dashboard/cancel/${startDate}/${endDate}/${periodUnit}`),
                axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/dashboard/order-status/${startDate}/${endDate}/${periodUnit}`),
                axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/dashboard/sales/count/${startDate}/${endDate}/${periodUnit}`),
                axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/dashboard/product/count/${startDate}/${endDate}/${periodUnit}/${limitCnt}`),
                axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/dashboard/product/price/${startDate}/${endDate}/${periodUnit}/${limitCnt}`),
                axios.get(`${process.env.REACT_APP_BOT_API_DOMAIN}/dashboard/sales/count/time/${startDate}/${endDate}/${periodUnit}`)
            ]).then(
                axios.spread((resSales, resCancel, resOrderStatus, resOrdersCnt, resProductCnt, resProductPrice, resOrdersCntData) => {
                    const totalCnt = resOrdersCntData.data.reduce((acc: any, current: any) => acc + current.orderCntByPeriodDateList.reduce((total: any, dateItem: any) => total + dateItem.orderCnt, 0), 0);

                    const res = {
                        selectedCondition: selectedCondition,
                        salesData: [
                            { id: "카카오 선물하기", data: resSales.data.map(((i: any) => ({ x: i.startDate.slice(0, 10), y: i.kakaoGiftPayment }))) },
                            { id: "카카오톡 스토어", data: resSales.data.map(((i: any) => ({ x: i.startDate.slice(0, 10), y: i.kakaoTalkStorePayment }))) },
                            { id: "네이버스마트스토어", data: resSales.data.map(((i: any) => ({ x: i.startDate.slice(0, 10), y: i.naverPayment }))) },
                            { id: "카페24", data: resSales.data.map(((i: any) => ({ x: i.startDate.slice(0, 10), y: i.cafe24Payment }))) },
                            { id: "총 매출", data: resSales.data.map(((i: any) => ({ x: i.startDate.slice(0, 10), y: i.totalPayment }))) },
                        ],
                        cancelData: resCancel.data.map((i: ICancelDataInDashboard) => ({
                            date: i.startDate.slice(0, 10),
                            kakaoGift: i.kakaoGift,
                            kakaoTalkStore: i.kakaoTalkStore,
                            naver: i.naver,
                            cafe24: i.cafe24
                        })),
                        orderStatusData: [
                            { id: "주문 취소 비율", data: resOrderStatus.data.map(((i: any) => ({ x: i.startDate.slice(0, 10), y: i.successCnt === 0 ? 0 : (i.cancelCnt / i.successCnt).toFixed(4) }))) },
                        ],
                        ordersCntData: [
                            { id: "카카오 선물하기", data: resOrdersCnt.data.map(((i: any) => ({ x: i.startDate.slice(0, 10), y: i.kakaoGiftCnt }))) },
                            { id: "카카오톡 스토어", data: resOrdersCnt.data.map(((i: any) => ({ x: i.startDate.slice(0, 10), y: i.kakaoTalkStoreCnt }))) },
                            { id: "네이버스마트스토어", data: resOrdersCnt.data.map(((i: any) => ({ x: i.startDate.slice(0, 10), y: i.naverCnt }))) },
                            { id: "카페24", data: resOrdersCnt.data.map(((i: any) => ({ x: i.startDate.slice(0, 10), y: i.cafe24Cnt }))) },
                            { id: "총 건수", data: resOrdersCnt.data.map(((i: any) => ({ x: i.startDate.slice(0, 10), y: i.totalCnt }))) },
                        ],
                        productCntData: resProductCnt.data.map((productCntData: any) => (
                            {
                                id: productCntData.productName.slice(0, 30),
                                data: productCntData.productCntDetailList.map((productCntDetail: any) => ({ x: productCntDetail.startDate.slice(0, 10), y: productCntDetail.productCnt }))
                            })),
                        productPriceData: resProductPrice.data.map((productPriceData: any) => (
                            {
                                id: productPriceData.productName.slice(0, 30),
                                data: productPriceData.productPriceDetailList.map((productPriceDetail: any) => ({ x: productPriceDetail.startDate.slice(0, 10), y: productPriceDetail.productPrice }))
                            })),
                        ordersCntRatioData: resOrdersCntData.data.map((i: any) =>
                        ({
                            id: `${i.dateTime}시`,
                            label: `${i.dateTime} (${(i.orderCntByPeriodDateList.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.orderCnt, 0) / totalCnt * 100).toFixed(2)}%)`,
                            value: i.orderCntByPeriodDateList.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.orderCnt, 0)
                        })),
                        //---------- 3시간씩 분류된 데이터 시작 -----------
                        // const target: any = [];
                        // const totalCnt = res.data.reduce((acc, current) => acc + current.orderCntByPeriodDateList.reduce((total, dateItem) => total + dateItem.orderCnt, 0), 0);
                        // console.log("totalCnt", totalCnt)
                        // res.data.map((acc, index) => {
                        //     if ((index + 3) % 3 === 0) {
                        //         const chunk = res.data.slice(index, index + 3);
                        //         const totalOrderCnt = chunk.reduce((sum, currentItem) => sum + currentItem.orderCntByPeriodDateList.reduce((total, dateItem) => total + dateItem.orderCnt, 0), 0);
                        //         const RatioData = {
                        //             id: `${chunk[0].dateTime}시 ~ ${chunk[2].dateTime}시 까지`,
                        //             label: `${chunk[0].dateTime} ~ ${chunk[2].dateTime} (${(totalOrderCnt / totalCnt * 100).toFixed(2)}%)`,
                        //             value: totalOrderCnt,
                        //         };
                        //         target.push(RatioData)
                        //     }
                        //     return target;
                        // })
                        // setOrdersCntRatioData(target.sort((a: any, b: any) => b.value - a.value))
                        //---------- 3시간씩 분류된 데이터 끝 ----------
                        ordersCntTimeData: resOrdersCntData.data.map((i: any) => ({ id: i.dateTime, data: i.orderCntByPeriodDateList.map((i: any) => ({ x: i.salesDate.slice(0, 10), y: i.orderCnt })) }))
                    }
                    dispatch(success(res));
                })
            )
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};