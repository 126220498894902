import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { ProductAction } from './types';
import { getOrderProductPositbot } from '../../axios/Product';

import { getProductListAsync } from './actions';

export function getProductListThunk(startDate: string, endDate: string): ThunkAction<void, RootState, null, ProductAction> {
    return async dispatch => {
        const { request, success, failure } = getProductListAsync;
        dispatch(request());
        try {
            const orderProductList = await getOrderProductPositbot(startDate, endDate);
            dispatch(success(orderProductList));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};