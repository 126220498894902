import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { BuyReportAction } from './types';
import { getBuyReportPositbot } from '../../axios/BuyReport';

import { getBuyReportAsync } from './actions';

export function getBuyReportThunk(startDate: string, endDate: string, page: number, size: number, sort: string): ThunkAction<void, RootState, null, BuyReportAction> {
    return async dispatch => {
        const { request, success, failure } = getBuyReportAsync;
        dispatch(request());
        try {
            const buyReport = await getBuyReportPositbot(startDate, endDate, page, size, sort);
            const buyReportWithId = buyReport.map((buyReport: IBuyReportData, index: number) => ({ ...buyReport, id: index + 1 }))
            dispatch(success(buyReportWithId));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};