import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_ORDERLIST = 'order/GET_ORDERLIST';
export const GET_ORDERLIST_SUCCESS = 'order/GET_ORDERLIST_SUCCESS';
export const GET_ORDERLIST_ERROR = 'order/GET_ORDERLIST_ERROR';

export const getOrderListAsync = createAsyncAction(
    GET_ORDERLIST,
    GET_ORDERLIST_SUCCESS,
    GET_ORDERLIST_ERROR
)<undefined, IOrderData, AxiosError>();


