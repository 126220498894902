import { ConfigProvider } from 'antd';
import './App.css';
import ScrollTop from './components/ScrollTop';
import Routes from './routes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import locale from 'antd/locale/ko_KR';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
dayjs.locale('ko');

function App() {
  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          body {
            font-size: 14px;
          }
        `,
      },
    },
  });

  return (<>
    <ThemeProvider theme={theme}>
      <ScrollTop>
        <ConfigProvider locale={locale}>
          <Routes />
        </ConfigProvider>
      </ScrollTop>
    </ThemeProvider>
  </>);
}

export default App;
