import { createReducer } from 'typesafe-actions';
import { CustomerState, CustomerAction } from './types';
import {
    GET_CUSTOMERLIST,
    GET_CUSTOMERLIST_SUCCESS,
    GET_CUSTOMERLIST_ERROR
} from './actions';


const initialState: CustomerState = {
    customerList: {
        customerLoading: false,
        customerError: null,
        customerData: null
    }
};

const customer = createReducer<CustomerState, CustomerAction>(initialState, {
    [GET_CUSTOMERLIST]: state => ({
        ...state,
        customerList: {
            customerLoading: true,
            customerError: null,
            customerData: null
        }
    }),
    [GET_CUSTOMERLIST_SUCCESS]: (state, action) => ({
        ...state,
        customerList: {
            customerLoading: false,
            customerError: null,
            customerData: action.payload
        }
    }),
    [GET_CUSTOMERLIST_ERROR]: (state, action) => ({
        ...state,
        customerList: {
            customerLoading: false,
            customerError: action.payload,
            customerData: null
        }
    }),
});

export default customer;