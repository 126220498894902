import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_PRODUCTLIST = 'product/GET_PRODUCTLIST';
export const GET_PRODUCTLIST_SUCCESS = 'product/GET_PRODUCTLIST_SUCCESS';
export const GET_PRODUCTLIST_ERROR = 'product/GET_PRODUCTLIST_ERROR';

export const getProductListAsync = createAsyncAction(
    GET_PRODUCTLIST,
    GET_PRODUCTLIST_SUCCESS,
    GET_PRODUCTLIST_ERROR
)<undefined, Array<IProductData>, AxiosError>();


