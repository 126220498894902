import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib';

interface IProps {
    groupSaleNameList: Array<string>
    onChange: (e: RadioChangeEvent) => void
    value: string
}

const OrderFilterCheckBox = ({ groupSaleNameList, onChange, value }: IProps) => {
    const plainOptions = ['전체', '공구 상품 제외', ...groupSaleNameList];

    return <>
        <Radio.Group options={plainOptions} onChange={onChange} value={value} />
    </>
}

export default OrderFilterCheckBox;