import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { getOtooDeliveryShipmentDateBetweenByDay } from "../../../../axios/Otoo";
import DailySalesDataGraph from "../dailySalesDataGraph";
import { getSaleCountForGroupSaleByOneMonth } from "../../../../axios/GroupSale";

interface ITableData extends IChannelAndShipmentDateBetween {
}

interface IColumnMapping {
    [key: string]: string | number;
}

export const columnMapping: IColumnMapping = {
    itemCode: '코드',
    item_code: '코드',
    itemName: '이름',
    product_name: '이름',
    '29CM': '29CM',
    _29CM: '29CM',
    b2C: 'B2C',
    headQuarter: '본사',
    naverSmartStore: '스마트스토어',
    offlineMarket: '오프라인 매장',
    kakaoGift: '카카오 선물하기',
    kakaoStore: '카카오 스토어',
    kakao: '카카오',
    cafe24: '카페24',
    overseas: '해외',
    total: '합계',
    shipment_date: '날짜',
};

interface IProps {
    targetValue: IOtooDeliveryShipmentDateBetweenAndProductNameByDay
    isExceptGorupSale?: boolean;
}

const DailySalesDataTable = ({ targetValue, isExceptGorupSale }: IProps) => {
    const [dailyTrendTableColumns, setDailyTrendTableColumns] = useState<ColumnsType<ITableData>>();
    const [dailyTrendDataList, setDailyTrendDataList] = useState<any[]>();

    useEffect(() => {
        if (!targetValue) return;
        requestData(targetValue)
    }, [targetValue, isExceptGorupSale])

    const requestData = async (targetValue: IOtooDeliveryShipmentDateBetweenAndProductNameByDay) => {
        if (isExceptGorupSale) {
            getOtooDeliveryShipmentDateBetweenByDay(targetValue)
                .then((res) => setDailyTrendDataList(res.data))
                .catch((err) => console.error('err', err));
        } else {
            try {
                const [salesDataListRes, groupSalesDataListRes] = await Promise.all([
                    getOtooDeliveryShipmentDateBetweenByDay(targetValue),
                    getSaleCountForGroupSaleByOneMonth(targetValue.targetYearMonth)
                ]);

                const salesDataList = salesDataListRes.data;
                const groupSalesDataList = groupSalesDataListRes.data.data;

                const adjustedSaleData = salesDataList.map(saleItem => {
                    const updatedSaleItem = { ...saleItem };
                    const eventItem = groupSalesDataList.find((e: any) => e.order_date === saleItem.shipment_date);
                    if (eventItem) {
                        Object.keys(updatedSaleItem).forEach(key => {
                            if (key !== "itemcode" && key !== "order_date" && !isNaN(updatedSaleItem[key])) {
                                updatedSaleItem[key] = (updatedSaleItem[key] || 0) - (eventItem[key] || 0);
                            }
                        });
                    }
                    return updatedSaleItem;
                });

                setDailyTrendDataList(adjustedSaleData);

            } catch (err) {
                console.error('err', err)
            }
        }

    }

    useEffect(() => {
        if (!dailyTrendDataList || dailyTrendDataList.length < 1) return;
        let dailyTrendClassfyColumns: ColumnsType<ITableData> = []
        const calcDailyDifference = (value: number, idx: number, key: string) => value - (idx + 1 < dailyTrendDataList.length ? dailyTrendDataList[idx + 1][key] : 0);
        for (const key in dailyTrendDataList[0]) {
            const title = columnMapping[key] ?? key
            title === '날짜'
                ? dailyTrendClassfyColumns.push({
                    title: title,
                    dataIndex: key,
                    width: '130px',
                })
                : dailyTrendClassfyColumns.push({
                    title: title,
                    dataIndex: key,
                    width: '130px',
                    render: (q, record, idx) => <div style={{ color: calcDailyDifference(q, idx, key) < 0 ? 'blue' : calcDailyDifference(q, idx, key) === 0 ? 'black' : 'red' }}>{q?.toLocaleString() ?? q} ({calcDailyDifference(q, idx, key)})</div>
                })
        }
        dailyTrendClassfyColumns.sort((q, w) => {
            const priority: { [key: string]: number } = { '날짜': -1, '합계': 1 };
            const priorityQ = priority[q.title as number] ?? 0;
            const priorityW = priority[w.title as number] ?? 0;
            if (priorityQ !== priorityW) { return priorityQ - priorityW; }
            return (q.title as string).localeCompare(w.title as string);
        });
        setDailyTrendTableColumns(dailyTrendClassfyColumns)
    }, [dailyTrendDataList])

    return <>
        <br />
        <div>일별 판매 데이터</div>
        <div>{targetValue.targetYearMonth}</div>
        <div>{targetValue.itemCode}</div>
        <br />
        <Table
            size="small"
            columns={dailyTrendTableColumns}
            dataSource={dailyTrendDataList?.map((q, idx) => ({ ...q, key: idx }))} scroll={{ y: 500 }} pagination={false} />
        {dailyTrendDataList && <DailySalesDataGraph dailyTrendDataList={dailyTrendDataList} />}
    </>
}

export default DailySalesDataTable;