import { createReducer } from 'typesafe-actions';
import { BuyReportState, BuyReportAction } from './types';
import {
    GET_BUY_REPORT,
    GET_BUY_REPORT_SUCCESS,
    GET_BUY_REPORT_ERROR
} from './actions';


const initialState: BuyReportState = {
    buyReport: {
        buyReportLoading: false,
        buyReportError: null,
        buyReportData: null
    }
};

const buyReport = createReducer<BuyReportState, BuyReportAction>(initialState, {
    [GET_BUY_REPORT]: state => ({
        ...state,
        buyReport: {
            buyReportLoading: true,
            buyReportError: null,
            buyReportData: null
        }
    }),
    [GET_BUY_REPORT_SUCCESS]: (state, action) => ({
        ...state,
        buyReport: {
            buyReportLoading: false,
            buyReportError: null,
            buyReportData: action.payload
        }
    }),
    [GET_BUY_REPORT_ERROR]: (state, action) => ({
        ...state,
        buyReport: {
            buyReportLoading: false,
            buyReportError: action.payload,
            buyReportData: null
        }
    }),
});

export default buyReport;