import { useEffect, useState } from "react";
import DashBoardChart from "../../../../components/chart/dashboard";
import { columnMapping } from "../dailySalesDataTable";
interface IProps {
    dailyTrendDataList: any[];
}
const DailySalesDataGraph = ({ dailyTrendDataList }: IProps) => {
    const [data, setData] = useState<any>();
    const transferDataForGraph = (dailyTrendDataList: any[]) => {
        return Object.entries(
            dailyTrendDataList.reduce((acc, q) => {
                Object.entries(q).forEach(([key, value]) => {
                    if (key !== 'shipment_date') {
                        const dataPoint = { x: q.shipment_date, y: value, value: key };
                        acc[key] = acc[key] || [];
                        acc[key].push(dataPoint);
                    }
                });
                return acc;
            }, {})
        ).map(([key, data]) => ({ id: columnMapping[key], data }));
    };

    useEffect(() => {
        setData(transferDataForGraph(dailyTrendDataList))
    }, [dailyTrendDataList])
    return <>
        <br />
        <div>일별 판매 데이터 그래프</div>
        <br />
        <div style={{ overflow: 'scroll' }}>
            <div style={{ height: '600px', width: '3000px' }}>
                <DashBoardChart salesData={data} />
            </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
    </>
}


export default DailySalesDataGraph;