import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_REPORT = 'report/GET_REPORT';
export const GET_REPORT_SUCCESS = 'report/GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'report/GET_REPORT_ERROR';

export const getReportAsync = createAsyncAction(
    GET_REPORT,
    GET_REPORT_SUCCESS,
    GET_REPORT_ERROR
)<undefined, IReportData, AxiosError>();


